<template>
  <div class="track-container">
    <mmx-widget-provider
      background="#ffffff"
      accent="#be9c82"
      use-defaults
      tenant-id="protein"
      :locale="locale"
    >
      <mmx-order-search />
      <mmx-shipment-iterator />
    </mmx-widget-provider>
  </div>
</template>
<script>
import config from 'config';

export default {
  name: 'Track',
  data () {
    return {
      locale: config.getflowbox.locale
    }
  },
  metaInfo: {
    script: [{ src: 'https://cdn.wismotion.com/js/mmx-widget/1.0/mmx-widget.js' }]
  }
}
</script>
<style lang="scss">
.track-container {
  padding-top: 30px;
  width: 450px;
  margin: 0px auto;
}
</style>
